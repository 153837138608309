import ajax from '@/utils/ajax.js'


/////////////////////     门店管理     ////////////////////
/**
 * 获取门店分类列表
 * @param {*} params 
 * @returns 
 */
export function getMallTypeList(params) {
  return ajax.post('/XcxMallPageGoodsType/getList',params)
}


/**
 * 修改小程序商城分类
 * @param {*} params 
 * @returns 
 */
 export function editMallType(params) {
  return ajax.post('/XcxMallPageGoodsType/edit',params)
}


/**
 * 获取小程序商城商品列表-某分类
 * @param {*} params 
 * @returns 
 */
export function getMallGoodsList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/getList',params)
}
/**
 * 小程序商城商品列表-全部分类
 * @param {*} params 
 * @returns 
 */
export function getMallAllGoodsList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/getAllList',params)
}
/**
 * 小程序商城商品列表-全部分类-排序
 * @param {*} params 
 * @returns 
 */
export function updateMallAllGoodsSortList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/allGoodsSort',params)
}


/**
 * 修改小程序商城分类商品
 * @param {*} params 
 * @returns 
 */
 export function editMallTypeGoods(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/editTypeGoods',params)
}



/**
 * 商品价格图片信息
 * @param {*} params 
 * @returns 
 */
 export function getGoodsPriceList(params) {
  return ajax.post('/XcxMallPageGoodsTypeGoods/getListAddUse',params)
}



/**
 * 下架商品
 * @param {*} params 
 * @returns 
 */
export function mallOffShelvesGoods(params) {
  return ajax.post('/MallShopGoodsTypeOther/offShelvesGoods',params)
}
